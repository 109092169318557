<template>
	<v-dialog v-model="viewMode" persistent max-width="1000px">
		<template v-slot:activator="{ on }">
			<v-btn color="primary" small rounded class="mx-2" v-on="on"> <v-icon left>mdi-open-in-app</v-icon> {{ $t('offers.view') }} </v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('offers.change') }} #{{ change.index }}</span>
				<v-spacer />
				<v-btn icon @click="switchViewMode()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-card color="transparent" flat rounded="xl">
					<v-card-title>
						<span style="word-break: normal" :class="[changedClassStyle('title')]">{{ change.title }}</span>
					</v-card-title>
					<v-card-text>
						<p>
							<span>{{ event.type.name }}</span>
							<v-icon>mdi-map-marker</v-icon>
							<span :class="[changedInfoClassStyle('location')]">{{ change.info.location }}</span>
						</p>
						<p>
							<span :class="[changedInfoTimestampClassStyle('start')]">
								<v-icon>mdi-calendar</v-icon> {{ humanTime(change.info.timestamp.start) }}
							</span>
							<span> - </span>
							<span :class="[changedInfoTimestampClassStyle('end')]">{{ humanTime(change.info.timestamp.end) }}</span>
						</p>
						<p :class="[changedInfoClassStyle('website')]"><v-icon>mdi-earth</v-icon> {{ change.info.website }}</p>
						<p :class="[changedInfoClassStyle('description')]" style="white-space: pre-line">{{ change.info.description }}</p>
					</v-card-text>
				</v-card>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'EventChange',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		},
		change: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			viewMode: false
		}
	},
	computed: {
		...mapGetters({
			event: 'events/event'
		})
	},
	methods: {
		humanTime(timestamp) {
			return this.$moment.utc(timestamp).format('YYYY-MM-DD HH:mm:ss')
		},
		changedClassStyle(attribute) {
			if (this.event[attribute] != this.change[attribute]) {
				return {
					'orange--text': true,
					'text--darken-1': true
				}
			}
		},
		changedInfoClassStyle(attribute) {
			if (this.event.info[attribute] != this.change.info[attribute]) {
				return {
					'orange--text': true,
					'text--darken-1': true
				}
			}
		},
		changedInfoTimestampClassStyle(attribute) {
			if (this.event.info.timestamp[attribute] != this.change.info.timestamp[attribute]) {
				return {
					'orange--text': true,
					'text--darken-1': true
				}
			}
		},
		switchViewMode() {
			this.viewMode = !this.viewMode
		}
	}
}
</script>
